import React from 'react';
import { Container } from 'react-bootstrap';

const Footer: React.FC = () => (
  <footer>
    <Container>
      <p className="footer-copyright">© Groupe FP. Tous droits réservés</p>
    </Container>
  </footer>
);
export default Footer;
