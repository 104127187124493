import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { SectionIds } from '../enums/SectionIds';
import LogoPath from '../images/smallLogo.png';
import Image from './Image';

const Navigation: React.FC = () => (
  <header>
    <Navbar variant="dark" expand="lg">
      <Navbar.Brand href={`#${SectionIds.HOME}`}>
        <Image path={LogoPath} alt="Logo" className="d-inline-block align-top nav-logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href={`#${SectionIds.SERVICE}`}>Services</Nav.Link>
          <Nav.Link href={`#${SectionIds.TEAM}`}>Équipe</Nav.Link>
          <Nav.Link href={`#${SectionIds.CONTACT}`}>Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>
);
export default Navigation;
