import React from 'react';
import { Col } from 'react-bootstrap';
import { ServiceItemProps } from '../../interfaces/ServiceItemProps';
import Image from '../Image';
const ServiceItem: React.FC<ServiceItemProps> = ({ imgPath, text }) => (
  <>
    <Col xs={6} md={2} className="img-box pt-4 pb-4">
      <Image path={imgPath} alt={text} className="service-image" />
    </Col>
    <Col xs={6} md={4} className="img-box pt-4 pb-4">
      <span>{text}</span>
    </Col>
  </>
);

export default ServiceItem;
