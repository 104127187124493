import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SectionIds } from '../../enums/SectionIds';
import Header from '../Header';
import Section from '../Section';
import ContactForm from './ContactForm';

const Contact: React.FC = () => (
  <Section id={SectionIds.CONTACT} className="pt-5 pb-5">
    <Container>
      <Row className="service-section">
        <Col xs={12}>
          <Header level="1" text="Contact" />
        </Col>
        <Col xs={12}>
          <ContactForm />
        </Col>
      </Row>
    </Container>
  </Section>
);

export default Contact;
