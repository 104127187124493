import React from 'react';
import Footer from './Footer';
import Navigation from './Navigation';

const Layout: React.FC = ({ children }) => (
  <>
    <Navigation />
    <main>{children}</main>
    <Footer />
  </>
);
export default Layout;
