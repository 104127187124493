import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SectionIds } from '../../enums/SectionIds';
import LogoPath from '../../images/logo.png';
import Header from '../Header';
import Image from '../Image';
import Section from '../Section';

const Welcome: React.FC = () => (
  <Section id={SectionIds.HOME} className="background-image">
    <Row className="no-padding-margin">
      <Col md={6} className="background-dark full-height logoBlock">
        <div className="text-center mx-auto">
          <Image path={LogoPath} alt="Logo" className="logo mx-auto" />
          <Header level="1" text="Groupe FP" cssClass="welcome-title" />
          <Header level="2" text="Services & conseils financiers aux entreprises" cssClass="welcome-subtitle" />
        </div>
      </Col>
    </Row>
  </Section>
);
export default Welcome;
