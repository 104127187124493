import React, { FormEvent, useRef, useState } from 'react';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { UIVariant } from '../../enums/UIVariant';

const ContactForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [alertVariant, setAlertVariant] = useState<string>(UIVariant.SUCCESS);

  const [token, setToken] = useState('');
  const firstNameInputRef = useRef<HTMLInputElement>(null);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
  const lastNameInputRef = useRef<HTMLInputElement>(null);
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');

  const emailInputRef = useRef<HTMLInputElement>(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  const companyInputRef = useRef<HTMLInputElement>(null);

  const phoneInputRef = useRef<HTMLInputElement>(null);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const [messageErrorMessage, setMessageErrorMessage] = useState('');

  const formRef = useRef<HTMLFormElement>(null);

  function validateForm() {
    let isFormValid = true;
    if (firstNameInputRef?.current?.value.trim() === '') {
      isFormValid = false;
      setFirstNameErrorMessage('Le prénom ne peut etre vide');
    }

    if (lastNameInputRef?.current?.value.trim() === '') {
      isFormValid = false;
      setLastNameErrorMessage('Le nom de famille ne peut etre vide');
    }

    if (emailInputRef?.current?.value.trim() === '') {
      isFormValid = false;
      setEmailErrorMessage('Le courriel ne peut etre vide');
    } else if (!emailInputRef?.current?.value.includes('@')) {
      isFormValid = false;
      setEmailErrorMessage('Le courriel doit contenir un @');
    }

    if (!phoneInputRef?.current?.value.trim().match(/^\d{10}$/g)) {
      isFormValid = false;
      setPhoneErrorMessage('Le téléphone doit etre au format 5551239876');
    }

    if (messageInputRef?.current?.value.trim() === '') {
      isFormValid = false;
      setMessageErrorMessage('Le message ne peut etre vide');
    }
    if (token === '') {
      isFormValid = false;
    }

    return isFormValid;
  }

  function clearForm() {
    formRef.current?.reset();
    setMessageErrorMessage('');
    setPhoneErrorMessage('');
    setEmailErrorMessage('');
    setLastNameErrorMessage('');
    setFirstNameErrorMessage('');
  }

  async function onSubmitHandler(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);

    if (validateForm()) {
      try {
        const url = 'https://us-central1-groupefp-9a4a8.cloudfunctions.net/emailSender';
        const response = await fetch(url, {
          headers: {
            Accept: 'application/json',
            'Content-Type': ' application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            firstname: firstNameInputRef.current?.value.trim(),
            lastname: lastNameInputRef.current?.value.trim(),
            email: emailInputRef.current?.value.trim(),
            phone: phoneInputRef.current?.value.trim(),
            company: companyInputRef.current?.value.trim(),
            message: messageInputRef.current?.value.trim(),
            'g-recaptcha-response': token,
          }),
        });
        if (response.ok) {
          setAlertVariant(UIVariant.SUCCESS);
          setAlertText('Le message a bien été envoyé');
          clearForm();
        } else {
          setAlertVariant(UIVariant.DANGER);
          setAlertText("Une erreur s'est produite lors de l'envoi");
        }
      } catch (err) {
        setAlertVariant(UIVariant.DANGER);
        setAlertText(`Une erreur s'est produite - ${err}`);
      }
    } else {
      setAlertVariant(UIVariant.DANGER);
      setAlertText("Une erreur s'est produite");
    }

    setIsLoading(false);
  }

  return (
    <>
      {alertText && <Alert variant={alertVariant}>{alertText}</Alert>}

      <Form onSubmit={onSubmitHandler} ref={formRef}>
        <Row>
          <Col xs={12} sm={6} className="mt-4">
            <Form.Control
              type="text"
              placeholder="Prénom *"
              ref={firstNameInputRef}
              required
              isInvalid={firstNameErrorMessage !== ''}
            />
            <Form.Control.Feedback type="invalid">{firstNameErrorMessage}</Form.Control.Feedback>
          </Col>
          <Col xs={12} sm={6} className="mt-4">
            <Form.Control
              type="text"
              placeholder="Nom *"
              ref={lastNameInputRef}
              required
              isInvalid={lastNameErrorMessage !== ''}
            />
            <Form.Control.Feedback type="invalid">{lastNameErrorMessage}</Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} className="mt-4">
            <Form.Control
              type="email"
              placeholder="Courriel *"
              ref={emailInputRef}
              required
              isInvalid={emailErrorMessage !== ''}
            />
            <Form.Control.Feedback type="invalid">{emailErrorMessage}</Form.Control.Feedback>
          </Col>
          <Col xs={12} sm={6} className="mt-4">
            <Form.Control type="text" placeholder="Compagnie (facultatif)" ref={companyInputRef} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-4">
            <Form.Control
              type="phone"
              placeholder="Téléphone (facultatif)"
              ref={phoneInputRef}
              isInvalid={phoneErrorMessage !== ''}
            />
            <Form.Control.Feedback type="invalid">{phoneErrorMessage}</Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-4">
            <Form.Control
              as="textarea"
              rows={6}
              placeholder="Message *"
              ref={messageInputRef}
              isInvalid={messageErrorMessage !== ''}
            />
            <Form.Control.Feedback type="invalid">{messageErrorMessage}</Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-4">
            <GoogleReCaptcha
              onVerify={(token) => {
                setToken(token);
              }}
            />
          </Col>
          <Col xs={12} className="mt-4">
            <Button type="submit">Envoyer</Button>
            {isLoading && (
              <>
                <Spinner animation="border" />
                Envoi en cours...
              </>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ContactForm;
