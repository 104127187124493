import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SectionIds } from '../../enums/SectionIds';
import LinkedInHoverImagePath from '../../images/in-hover.png';
import LinkedInImagePath from '../../images/in.png';
import JeanImagePath from '../../images/JEAN.jpg';
import EmailHoverImagePath from '../../images/mail-hover.png';
import EmailImagePath from '../../images/mail.png';
import PhoneHoverImagePath from '../../images/Tel-hover.png';
import PhoneImagePath from '../../images/Tel.png';
import Header from '../Header';
import Image from '../Image';
import Section from '../Section';

const Team: React.FC = () => (
  <Section id={SectionIds.TEAM} className="section-dark pt-5 pb-5">
    <Container>
      <Row className="service-section">
        <Col xs={12}>
          <Header level="1" text="Notre équipe" />
        </Col>
        <Col xs={12} md={4} className="text-center">
          <Image path={JeanImagePath} alt="Jean" className="img-fluid service-image-big" />
        </Col>
        <Col xs={12} md={8}>
          <Header level="2" text="Jean Fortin" />
          <p>
            Détenteur d’un MBA en financement des PME et comptant plus de 38 années d’expérience en financement
            d’entreprises, négociation, développement des affaires et gestion d’équipes de vente pour de grandes
            institutions, je veux partager mes connaissances, mes compétences et mon réseau de contacts avec les
            entrepreneurs, et ainsi leur permettre de réaliser leur projet d’entreprise.
          </p>
          <div className="team-display-flex">
            <a href="https://www.linkedin.com/in/jean-fortin-25879a34/" target="_blank" rel="noreferrer">
              <Image
                path={LinkedInImagePath}
                alt="LinkedIn"
                className="team-social"
                pathHover={LinkedInHoverImagePath}
              />
            </a>
            <a href="mailto:jean.fortin.fp@gmail.com">
              <Image path={EmailImagePath} alt="LinkedIn" className="team-social" pathHover={EmailHoverImagePath} />
            </a>
            <a href="tel:+15819946717">
              <Image path={PhoneImagePath} alt="LinkedIn" className="team-social" pathHover={PhoneHoverImagePath} />
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  </Section>
);
export default Team;
