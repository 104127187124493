import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SectionIds } from '../../enums/SectionIds';
import AchatVenteImagePath from '../../images/Achat-Vente.png';
import AidePreparationImagePath from '../../images/Aide-preparation.png';
import ConseilsFinanciersImagePath from '../../images/Conseils-financiers.png';
import FinancementFondsImagePath from '../../images/Financement-Fonds.png';
import FinancementBancaireImagePath from '../../images/Financements-bancaires.png';
import NegociationImagePath from '../../images/Negociation.png';
import ServiceImagePath from '../../images/Services.png';
import Header from '../Header';
import Image from '../Image';
import Section from '../Section';
import ServiceItem from './ServiceItem';

const Service: React.FC = () => (
  <Section id={SectionIds.SERVICE} className="pt-5 pb-5">
    <Container>
      <Row>
        <Col xs={12}>
          <Header level="1" text="Nos services" />
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <ServiceItem imgPath={ConseilsFinanciersImagePath} text="Conseils financiers" />
            <ServiceItem imgPath={FinancementBancaireImagePath} text="Financement bancaire traditionnel" />
          </Row>
          <Row>
            <ServiceItem imgPath={AidePreparationImagePath} text="Aide à la préparation d'un projet de financement" />
            <ServiceItem imgPath={FinancementFondsImagePath} text="Financements avec des Fonds d'investissement" />
          </Row>
          <Row>
            <ServiceItem imgPath={NegociationImagePath} text="Négociation avec les banques" />
            <ServiceItem imgPath={AchatVenteImagePath} text="Achat / Vente / Fusion d'entreprises" />
          </Row>
        </Col>
        <Col xs={12} md={6} className="text-center">
          <Image path={ServiceImagePath} alt="Services" className="img-fluid service-image-big" />
        </Col>
      </Row>
    </Container>
  </Section>
);

export default Service;
