import React from 'react';
import Contact from '../components/sections/Contact';
import Service from '../components/sections/Service';
import Team from '../components/sections/Team';
import Welcome from '../components/sections/Welcome';

const Home: React.FC = () => (
  <>
    <Welcome />
    <Service />
    <Team />
    <Contact />
  </>
);
export default Home;
