import React from 'react';
import { HeaderProps } from '../interfaces/HeaderProps';

const Header: React.FC<HeaderProps> = ({ level, text, cssClass }) => {
  let result = <></>;
  switch (level) {
    case '1':
      result = <h1 className={cssClass}>{text}</h1>;
      break;
    case '2':
      result = <h2 className={cssClass}>{text}</h2>;
      break;
    case '3':
      result = <h3 className={cssClass}>{text}</h3>;
      break;
    case '4':
      result = <h4 className={cssClass}>{text}</h4>;
      break;
    case '5':
      result = <h5 className={cssClass}>{text}</h5>;
      break;
    case '6':
      result = <h6 className={cssClass}>{text}</h6>;
      break;
    default:
      result = <h1 className={cssClass}>{text}</h1>;
  }
  return result;
};

export default Header;
