import React, { useState } from 'react';

interface ImageProps {
  path: string;
  alt: string;
  className: string;
  pathHover?: string;
}

// eslint-disable-next-line object-curly-newline
const Image: React.FC<ImageProps> = ({ path, alt, className, pathHover }) => {
  const [imgPath, setImgPath] = useState(path);

  return (
    <img
      src={imgPath}
      alt={alt}
      title={alt}
      className={className}
      onMouseEnter={() => {
        setImgPath(pathHover || path);
      }}
      onMouseLeave={() => setImgPath(path)}
    />
  );
};

Image.defaultProps = {
  pathHover: '',
};
export default Image;
